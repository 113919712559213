
    // @ is an alias to /src
    import CarouselPluginCard from "@/components/carousel_cards/carousel_plugin_card.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            CarouselPluginCard,
        },
    })

    // *****************************************************************************************************************
    export default class DevUnityRiderCards extends Vue {
        // Fields ------------------------------------------------------------------------------------------------------
        // tslint:disable
        private readonly cards = [
            {
                imagePathAndName: require("@/assets/images/rider_string_manipulation.png"),
                name: "Rider - String Manipulation",
                pros: [
                    "Switch case (camelCase, kebab-lowercase, KEBAB-UPPERCASE, snake_case, SCREAMING_SNAKE_CASE, dot.case, words lowercase, First word capitalized, Words Capitalized, PascalCase)"
                ],
                cons: [
                ],
                url: "",
                cardNumber: "1/11"
            },
            {
                imagePathAndName: require("@/assets/images/rider_python_community_edition.png"),
                name: "Rider - Python Community Edition",
                pros: [
                    "Provides smart editing for Python scripts. The feature set of the plugin corresponds to PyCharm IDE Community Edition."
                ],
                cons: [
                ],
                url: "",
                cardNumber: "2/11"
            },
            {
                imagePathAndName: require("@/assets/images/rider_rainbow_brackets.png"),
                name: "Rider - Rainbow Brackets",
                pros: [
                    "Rainbow Brackets / Rainbow Parentheses for IntelliJ based IDEs"
                ],
                cons: [
                ],
                url: "",
                cardNumber: "3/11"
            },
            {
                imagePathAndName: require("@/assets/images/rider_markdown_navigator_enhanced.png"),
                name: "Rider - Markdown Navigator Enhanced",
                pros: [
                    "Markdown language support",
                    "Paste images into documents for fast screen capture links",
                    "Formatting to cleanup documents with a key stroke",
                    "Convert HTML to Markdown by pasting it into a Markdown document"
                ],
                cons: [
                    "£0.8 per month (Individual use)"
                ],
                url: "",
                cardNumber: "4/11"
            },
            {
                imagePathAndName: require("@/assets/images/rider_json_parser.png"),
                name: "Rider - Json Parser",
                pros: [
                    "A lightweight plugin for validating and formatting JSON strings"
                ],
                cons: [
                ],
                url: "",
                cardNumber: "5/11"
            },
            {
                imagePathAndName: require("@/assets/images/rider_git_tool_box.png"),
                name: "Rider - GitToolBox",
                pros: [
                    "Status display, auto fetch, inline blame annotation, commit dialog completion, behind notifications and more..."
                ],
                cons: [
                ],
                url: "",
                cardNumber: "6/11"
            },
            {
                imagePathAndName: require("@/assets/images/rider_heap_allocation_viewer.png"),
                name: "Rider - Heap Allocation Viewer",
                pros: [
                    "Highlights local object allocations, boxing, delegates and closure creations points"
                ],
                cons: [
                ],
                url: "",
                cardNumber: "7/11"
            },
            {
                imagePathAndName: require("@/assets/images/rider_cyclomatic_complexity.png"),
                name: "Rider - Cyclomatic Complexity",
                pros: [
                    "Calculates cyclomatic complexity for C#, VB, JavaScript, TypeScript and C++",
                    "Highlights any method that exceeds a configurable threshold"
                ],
                cons: [
                ],
                url: "",
                cardNumber: "8/11"
            },
            {
                imagePathAndName: require("@/assets/images/rider_cognitive_complexity.png"),
                name: "Rider - Cognitive Complexity",
                pros: [
                    "Live calculation of the Cognitive Complexity metric, which was proposed by G. Ann Campbell"
                ],
                cons: [
                ],
                url: "",
                cardNumber: "9/11"
            },
            {
                imagePathAndName: require("@/assets/images/rider_code_glance.png"),
                name: "Rider - Code Glance",
                pros: [
                    "Embeds a code minimap similar to the one found in Sublime into the editor pane",
                    "Works with both light and dark themes using your customized colors for syntax highlighting"
                ],
                cons: [
                ],
                url: "",
                cardNumber: "10/11"
            },
            {
                imagePathAndName: require("@/assets/images/rider_vue_js.png"),
                name: "Rider - Vue.js",
                pros: [
                    "Support for Vue.js projects",
                    "Completion for components, directives, props and methods in templates",
                    "Navigation from the component, directives and event handlers to their definition",
                    "Coding assistance for different languages inside template, script and style tags in .vue files"
                ],
                cons: [
                ],
                url: "",
                cardNumber: "11/11"
            },
        ];
        // tslint:enable

        // Methods -----------------------------------------------------------------------------------------------------
        private data() {
            return {
                prime31ImagePathAndName:
                    require("@/assets/images/prime31.png"),
            };
        }
    }
