
    // @ is an alias to /src
    import CarouselPluginCard from "@/components/carousel_cards/carousel_plugin_card.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            CarouselPluginCard,
        },
    })

    // *****************************************************************************************************************
    export default class DevUnityPluginCards extends Vue {
        // Fields ------------------------------------------------------------------------------------------------------
        // tslint:disable
        private readonly cards = [
            {
                imagePathAndName: require("@/assets/images/prime31.png"),
                name: "prime31 - Etcetera",
                pros: [
                    "Push notifications, native alerts, web view, mail composer, SMS composer",
                    "Programmatic screenshots, rate this app, photo/camera picer, image resizing"
                ],
                cons: [
                    "Costs €58.07",
                    "Has to be bought separately for Android and iOS"
                ],
                url: "https://assetstore.unity.com/packages/tools/utilities/ios-etcetera-plugin-115787",
                cardNumber: "1/11"
            },
            {
                imagePathAndName: require("@/assets/images/odin_inspector_and_serializer.png"),
                name: "Odin Inspector & Serializer",
                pros: [
                    "Open-source",
                    "Serialize Dictionaries, Properties, Generics and Multi-dimensional arrays etc by inheriting from SeriailzedScriptableObject",
                    "Both Binary and Json formats"
                ],
                cons: [
                    "Costs €49.13"
                ],
                url: "https://assetstore.unity.com/packages/tools/utilities/odin-inspector-and-serializer-89041",
                cardNumber: "2/11"
            },
            {
                imagePathAndName: require("@/assets/images/editor_console_pro.png"),
                name: "Editor Console Pro",
                pros: [
                    "Filter all entries by text, file name, class, GameObject name on the fly",
                    "Create custom filter groups with their own colors, icons, and toggle buttons",
                    "See the source code surrounding each method call in the stack"
                ],
                cons: [
                    "€26.80"
                ],
                url: "https://assetstore.unity.com/packages/tools/utilities/editor-console-pro-11889",
                cardNumber: "3/11"
            },
            {
                imagePathAndName: require("@/assets/images/vr_user_interfaces.png"),
                name: "VR User Interfaces",
                pros: [
                    "Create VR Pointer / Touch Menus",
                    "Supports buttons, toggles and sliders, a basic keyboard and all receiver elements can use Animation states"
                ],
                cons: [
                    "€8.93"
                ],
                url: "https://assetstore.unity.com/packages/tools/gui/vr-user-interfaces-83649",
                cardNumber: "4/11"
            },
            {
                imagePathAndName: require("@/assets/images/lite_db.png"),
                name: "LiteDB",
                pros: [
                    "Serverless database delivered in a single small DLL (<450kb)",
                    "Desktop or Mobile Apps (Xamarin ready)",
                    "SQL-like language for data and structure manipulations",
                ],
                cons: [
                ],
                url: "https://www.litedb.org/",
                cardNumber: "5/11"
            },
            {
                imagePathAndName: require("@/assets/images/full_serializer.png"),
                name: "Full Serializer",
                pros: [
                    "Serialize pretty much anything you can throw at it",
                    "Usage is identical to Unity's default serialization, except that you don't have to mark types as [Serializable]",
                    "Auto-properties that are at least partially public are serialized by default."
                ],
                cons: [
                ],
                url: "https://github.com/jacobdufault/fullserializer",
                cardNumber: "6/11"
            },
            {
                imagePathAndName: require("@/assets/images/file_browser_pro.png"),
                name: "File Browser Pro",
                pros: [
                    "A wrapper for native file dialogs on Windows, macOS, Linux and UWP (WSA)"
                ],
                cons: [
                    "€33.95"
                ],
                url: "https://assetstore.unity.com/packages/tools/utilities/file-browser-pro-98713",
                cardNumber: "7/11"
            },
            {
                imagePathAndName: require("@/assets/images/best_http.png"),
                name: "Best HTTP/2",
                pros: [
                    "REST, WebSocket, Socket.IO, SignalR, SignalR Core, Server-Sent Events",
                    "Customize all parts of your requests: Methods(GET, HEAD, POST, PUT, DELETE, PATCH)"
                ],
                cons: [
                    "No support for non-SignalR streaming?",
                    "€53.60"
                ],
                url: "https://assetstore.unity.com/packages/tools/network/best-http-2-155981",
                cardNumber: "8/11"
            },
            {
                imagePathAndName: require("@/assets/images/text_mesh_pro.png"),
                name: "Text Mesh Pro",
                pros: [
                    "Uses Signed Distance Field (SDF) as its primary text rendering pipeline making it possible to render text cleanly at any point size and resolution"
                ],
                cons: [
                ],
                url: "https://docs.unity3d.com/Packages/com.unity.textmeshpro@1.3/manual/index.html",
                cardNumber: "9/11"
            },
            {
                imagePathAndName: require("@/assets/images/unity_gltf.png"),
                name: "Unity glTF",
                pros: [
                    "Run-time import of glTF 2.0 files",
                    "Run-time export of glTF 2.0 files"
                ],
                cons: [
                    "Not being worked on as much as it should be!"
                ],
                url: "https://github.com/KhronosGroup/UnityGLTF",
                cardNumber: "10/11"
            },
            {
                imagePathAndName: require("@/assets/images/obfuscator.png"),
                name: "Obfuscator",
                pros: [
                    "The contents of your source files are unchanged, the obfuscation targets the compiled assembly.",
                    "Supports IL2CPP"
                ],
                cons: [
                    "Slow, use in release builds only",
                    "€62.53"
                ],
                url: "https://assetstore.unity.com/packages/tools/utilities/obfuscator-48919",
                cardNumber: "11/11"
            },
        ];
        // tslint:enable

        // Methods -----------------------------------------------------------------------------------------------------
        private data() {
            return {
                prime31ImagePathAndName:
                    require("@/assets/images/prime31.png"),
            };
        }
    }
