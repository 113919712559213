
    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class CarouselPluginCard extends Vue {
        // Properties
        @Prop() private readonly data!: object;
    }
